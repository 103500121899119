import React, { Component } from "react";
import { Collapse, Row, Col, Button } from "antd";
import moment from "moment";
import axios from "axios";
import Lightbox from './lightbox';
const { Panel } = Collapse;

const text = `
  Thumbnail of all records will be shown here as cards. 3 columns and infinte running rows.
`;

export default class RecordsView extends Component {
	state = {
		pastReports: []
	};
	fetchReports = params => {
		axios
			.get(
				`${process.env.REACT_APP_API_ROOT}/past_visit_report?person_id=${this.props.location.state.patientId}`
			)
			.then(res => {
				this.setState({
					pastReports: res.data.list
				});
			});
	};
	componentDidMount() {
		this.fetchReports();
	}
	renderExtra = images => {
		switch (images.length) {
			case 0:
				return "No records";
				break;
			case 1:
				return "1 record";
			default:
				return `${images.length} records`;
				break;
		}
	};

	render() {
		return (
			<React.Fragment>
				<div
					style={{
						textAlign: "left",
						margin: "1rem"
					}}
				>
					<Row type="flex" style={{marginBottom: '1rem'}}>
						<Col span={12}>
							<h1>Past Records</h1>
						</Col>
						<Col span={12} style={{textAlign: 'right'}}>
							<Button icon="reload" onClick={() => this.fetchReports()}>
								Refresh
							</Button>
						</Col>
					</Row>
					<Collapse accordion style={{ textAlign: "left" }}>
						{this.state.pastReports.map((record, index) => {
							return (
								<Panel
									header={`${record.date}`}
									key={`${index + 1}`}
									extra={this.renderExtra(record.images)}
								>
									<Lightbox images={record.images} />
								</Panel>
							);
						})}
					</Collapse>
				</div>
			</React.Fragment>
		);
	}
}
