import React, { Component } from "react";
import { Upload, Button, Icon, message, Row, Col, DatePicker } from "antd";
import reqwest from "reqwest";
import moment from "moment";
import axios from "axios";

const dateFormat = "YYYY-MM-DD";
export default class RecordUpload extends Component {
	state = {
		submitting: false,
		uploading: false,
		fileList: [],
		uploadImageList: [],
		visitDate: moment(new Date(), dateFormat),
		currentUploadIndex: 0
	};
	shouldInitiateUpload = () => {
		if (this.state.currentUploadIndex !== this.state.fileList.length) {
			this.uploadHandler();
		} else {
			this.submitRecord();
		}
	};

	uploadHandler = () => {
		const { fileList, currentUploadIndex } = this.state;
		const currentItem = fileList[currentUploadIndex];
		this.handleUpload(currentItem);
	};
	uploadSuccessHandler = resp => {
		if (this.state.currentUploadIndex !== this.state.fileList.length) {
			this.setState(
				state => ({
					currentUploadIndex: state.currentUploadIndex + 1,
					uploadImageList: [...state.uploadImageList, resp.image_id],
					uploading: false
				}),
				() => this.shouldInitiateUpload()
			);
		}
	};
	handleUpload = currentItem => {
		const formData = new FormData();
		formData.append("image", currentItem);

		this.setState({
			uploading: true
		});

		// You can use any AJAX library you like
		reqwest({
			url: `${process.env.REACT_APP_API_ROOT}/patient_add_image`,
			method: "post",
			processData: false,
			data: formData,
			success: resp => {
				this.uploadSuccessHandler(resp);
			},
			error: () => {
				this.setState({
					uploading: false
				});
				message.error("upload failed.");
			}
		});
	};
	visitDateChange = (date, dateString) => {
		this.setState({
			visitDate: dateString
		});
	};

	disabledDate = current => {
		return current && current > moment();
	};

	submitRecord = params => {
		this.setState({
			submitting: true
		});
		const data = {
			image_ids: this.state.uploadImageList.join(),
			date: moment(this.state.visitDate).format(dateFormat),
			person_id: this.props.location.state.patientId
		};
		axios
			.post(`${process.env.REACT_APP_API_ROOT}/past_visit_report`, data)
			.then(res => {
				message.success("Uploaded Successfully");
				this.setState(
					{
						submitting: false,
						fileList: [],
						uploadImageList: [],
						currentUploadIndex: 0
					},
					() => this.props.refresh()
				);
			})
			.catch(err => {
				message.success("Something went wrong. Please try again.");
				this.setState({
					submitting: false,
					currentUploadIndex: 0
				});
			});
	};

	render() {
		const { submitting, uploading, fileList, currentUploadIndex } = this.state;
		const props = {
			multiple: true,
			onRemove: file => {
				this.setState(state => {
					const index = state.fileList.indexOf(file);
					const newFileList = state.fileList.slice();
					newFileList.splice(index, 1);
					return {
						fileList: newFileList
					};
				});
			},
			beforeUpload: file => {
				this.setState(state => ({
					fileList: [...state.fileList, file]
				}));
				return false;
			},
			fileList
		};

		return (
			<React.Fragment>
				<Row
					type="flex"
					style={{
						textAlign: "left"
					}}
				>
					<Col>
						<h1>Upload Past Records</h1>
					</Col>
				</Row>
				<Row
					type="flex"
					style={{
						textAlign: "left",
						marginTop: "0.8rem"
					}}
				>
					<Col>
						<h5>Select Visit Date</h5>
					</Col>
				</Row>
				<Row
					type="flex"
					style={{
						textAlign: "left"
					}}
				>
					<Col>
						<DatePicker
							defaultValue={moment(new Date(), dateFormat)}
							format={dateFormat}
							onChange={this.visitDateChange}
							disabledDate={this.disabledDate}
							allowClear={false}
						/>
					</Col>
				</Row>
				<Row
					type="flex"
					style={{
						textAlign: "left",
						marginTop: "2rem"
					}}
				>
					<Col>
						<h5>Files to upload</h5>
					</Col>
				</Row>
				<Row
					type="flex"
					style={{
						textAlign: "left"
					}}
				>
					<Col>
						<Upload {...props}>
							<Button>
								<Icon type="upload" /> Select File(s)
							</Button>
						</Upload>
					</Col>
				</Row>
				<Row
					type="flex"
					style={{
						textAlign: "left"
					}}
				>
					{/* COMBAK: error in ternary */}
					{/* {currentUploadIndex === fileList.length ? (
						<Col>
							<Button
								type="primary"
								onClick={this.shouldInitiateUpload}
								disabled={fileList.length === 0}
								loading={uploading}
								style={{ marginTop: 16 }}
							>
								{uploading ? "Uploading" : "Start Upload"}
							</Button>
						</Col>
					) : (
						<Col>
							<Button
								type="primary"
								onClick={this.submitRecord}
								loading={submitting}
								style={{ marginTop: 16 }}
							>
								{submitting ? "Submitting" : "Submit"}
							</Button>
						</Col>
					)} */}
					<Col>
						<Button
							type="primary"
							onClick={this.shouldInitiateUpload}
							disabled={fileList.length === 0}
							loading={uploading}
							style={{ marginTop: 16 }}
						>
							{uploading ? "Uploading" : "Upload & Submit"}
						</Button>
					</Col>
					{/* <Col>
						<Button
							type="primary"
							onClick={this.submitRecord}
							loading={submitting}
							style={{ marginTop: 16 }}
						>
							{submitting ? "Submitting" : "Submit"}
						</Button>
					</Col> */}
				</Row>
			</React.Fragment>
		);
	}
}
