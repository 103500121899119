import React from "react";
import {LoginForm} from "./login";
import { API_ROOT } from "../util";

const Auth = (props) => (
  <div>
    <LoginForm title="Login" base_url={API_ROOT} props={props}/>
  </div>
);
export default Auth;
