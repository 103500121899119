import React, { Component } from "react";
import {
	Empty,
	Button,
	Layout,
	Row,
	Col,
	Avatar,
	PageHeader,
	Icon,
	Card,
	Select,
	Radio,
	Timeline,
	Steps,
	Popconfirm
} from "antd";
import SideBar from "../navigation/sidebar";
import axios from "axios";
import {openNotification } from '../util'
export default class TodaysInvestigation extends Component {
	state = {
		report_list: [],
		investigations: [],
		errorItem: null
	};
	onReportSelectionChange = (index, selectedReport) => {
		// console.log("onReportSelectionChange", index, selectedReport);
		let stateCopy = { ...this.state };
		let investigationItem = this.state.investigations[index];
		investigationItem.instruction_id = selectedReport;
		// console.log("investigationItem", investigationItem);
		this.setState(stateCopy);
	};
	onStatusChange = (index, event) => {
		// console.log("onStatusChange", index, event.target.value);
		let stateCopy = { ...this.state };
		let investigationItem = stateCopy.investigations[index];
		investigationItem.status = event.target.value;
		// console.log("investigationItem", investigationItem);
		this.setState(stateCopy);
	};
	onStatusChangeNew = (index, status, event) => {
		console.log("onStatusChange", index, status, event);
		let stateCopy = { ...this.state };
		let investigationItem = stateCopy.investigations[index];
		investigationItem.status = status;
		// console.log("investigationItem", investigationItem);
		this.setState(stateCopy);
	};
	checkEmptyInstructions = () => {
		let findItem = this.state.investigations.findIndex(
			x => x.instruction_id === null
		);
		return findItem === -1 ? false : findItem;
	}
	
	onAddNewInvestigation = () => {
		if (!this.checkEmptyInstructions()) {
			let obj = {
				instruction_id: null,
				status: 0
			};
			this.setState(state => ({
				investigations: [...state.investigations, obj],
				errorItem: null
			}));
		}
		else {
			let errorItemInQuestion =  this.checkEmptyInstructions()
			this.setState({ errorItem: errorItemInQuestion });
		}
	};
	onInvestigationDelete = (index, item) => {
		console.log("index", index);
		let stateCopy = { ...this.state };
		stateCopy.investigations.splice(index, 1);
		this.setState(stateCopy);
		console.log("stateCopy", stateCopy.investigations);
	};

	fetchReportList = () => {
		axios
			.get(
				`${
					process.env.REACT_APP_API_ROOT
				}/report_list?clinic_id=${localStorage.getItem("clinicId")}`
			)
			.then(res => {
				console.log(res.data);
				this.setState({
					report_list: res.data.instruction
				});
			});
	};
	fetchPreviousInvestigations = () => {
		axios
			.get(
				`${process.env.REACT_APP_API_ROOT}/today_report?appointment_id=${this.props.location.state.appointmentId}`
			)
			.then(res => {
				console.log(res.data);
				this.setState({
					investigations: res.data.list
				});
			});
	};
	onSubmitInvestigations = () => {
		console.log('checkEmptyInstructions', this.checkEmptyInstructions())
		if (this.checkEmptyInstructions() === false) {
			let payload = {
				appointment_id: this.props.location.state.appointmentId,
				list: []
			};
			this.state.investigations.map((item, indd) => {
				payload.list.push({
					...item
				});
			});
			console.log("payload", payload);
			//today_report
			axios
				.post(`${process.env.REACT_APP_API_ROOT}/today_report`, payload)
				.then(res => {
					console.log("onSubmitInvestigations", res.data);
					openNotification("success", "Submitted");
					this.setState({ errorItem: null }, () => {
						this.fetchPreviousInvestigations();
					});
					
				});
		} else {
			let errorItemInQuestion = this.checkEmptyInstructions();
			this.setState({ errorItem: errorItemInQuestion });
			openNotification("error", "Please select an investigation");
		}
		
	};

	componentDidMount() {
		document.title = "Investigations - /dr";
		this.fetchReportList();
		this.fetchPreviousInvestigations();
	}
	render() {
		const investigationStatus = ["ordered", "report received", "not conducted"];
		const { location } = this.props;
		return (
			<React.Fragment>
				<Layout>
					<PageHeader
						onBack={() => this.props.history.push("/dashboard")}
						title={
							<div>
								{location.state.avatar ? (
									<Avatar size={35} src={location.state.avatar} />
								) : (
									<Avatar size={35} icon="user" />
								)}
							</div>
						}
						subTitle={
							<div>
								{location.state.patientName}
								{location.state.patientAge}
								{location.state.gender !== null &&
								location.state.gender.length > 0
									? `/ ${location.state.gender}`
									: null}
							</div>
						}
						style={{
							textAlign: "left"
						}}
						backIcon={<Icon type="close" />}
					/>

					<Layout>
						<Layout.Sider
							style={{
								height: "100vh"
							}}
						>
							<SideBar {...this.props} />
						</Layout.Sider>
						<Layout.Content>
							{this.state.investigations.length ? (

							<Row gutter={16} type="flex" style={{ marginTop: "1rem" }}>
								<Col span={6} offset={1} style={{ textAlign: "left" }}>
									<h4>
										Today's investigations: {this.state.investigations.length}
									</h4>
								</Col>
								<Col span={3} offset={1}>
									<Button
										type="dashed"
										icon="plus"
										onClick={this.onAddNewInvestigation}
										style={{ width: "100%" }}
									>
										Add new
									</Button>
								</Col>
								<Col span={3}>
									<Button
										type="primary"
										onClick={this.onSubmitInvestigations}
										style={{ width: "100%" }}
									>
										Submit
									</Button>
								</Col>
							</Row>
							): null}
							<Row
								gutter={16}
								type="flex"
								justify="space-between"
								style={{
									height: "100vh",
									marginTop: '1rem'
								}}
							>
								<Col span={20} offset={1}>
									{this.state.investigations.length ? (
										<React.Fragment>
											<Row type="flex">
												<Col span={16}>
													{this.state.investigations.map((inv, index) => {
														return (
															<React.Fragment>
																<Card
																	style={{
																		textAlign: "left",
																		marginTop: "1rem",
																		borderColor:
																			this.state.errorItem === index
																				? "red"
																				: "#e8e8e8"
																	}}
																	title={
																		inv.hasOwnProperty("id") ? (
																			`${inv.name}`
																		) : (
																			<Select
																				defaultValue={inv.name}
																				onChange={this.onReportSelectionChange.bind(
																					this,
																					index
																				)}
																				style={{
																					width: 200
																				}}
																			>
																				{this.state.report_list.map(
																					(re, index) => {
																						return (
																							<Select.Option value={re.id}>
																								{re.english}
																							</Select.Option>
																						);
																					}
																				)}
																			</Select>
																		)
																	}
																	extra={
																		<Popconfirm
																			title="Are you sure you want to delete this?"
																			onConfirm={this.onInvestigationDelete.bind(
																				this,
																				index
																			)}
																			okText="Yes"
																			cancelText="No"
																		>
																			<Button type="danger" ghost>
																				Delete
																			</Button>
																		</Popconfirm>
																	}
																>
																	<Row
																		type="flex"
																		gutter={16}
																		style={{
																			marginTop: "0.5rem"
																		}}
																	>
																		{/*<Col span={24}>
																			 <Radio.Group
																				onChange={this.onStatusChange.bind(
																					this,
																					index
																				)}
																				value={
																					this.state.investigations[index]
																						.status
																				}
																				buttonStyle="solid"
																			>
																				{investigationStatus.map(
																					(item, ind) => {
																						return (
																							<Radio.Button value={ind}>
																								{item}
																							</Radio.Button>
																						);
																					}
																				)}
																			</Radio.Group>
																		</Col> */}
																		<Col span={8}>
																			<Button
																				icon="container"
																				onClick={this.onStatusChangeNew.bind(
																					this,
																					index,
																					0
																				)}
																				className={
																					inv.status === 0
																						? "investigation-ordered"
																						: "investigation-muted"
																				}
																			>
																				Ordered
																			</Button>
																		</Col>
																		<Col span={8}>
																			<Button
																				icon="check-circle"
																				onClick={this.onStatusChangeNew.bind(
																					this,
																					index,
																					1
																				)}
																				className={
																					inv.status === 1
																						? "investigation-received"
																						: "investigation-muted"
																				}
																			>
																				Report received
																			</Button>
																		</Col>
																		<Col span={8}>
																			<Button
																				icon="close-circle"
																				onClick={this.onStatusChangeNew.bind(
																					this,
																					index,
																					2
																				)}
																				className={
																					inv.status === 2
																						? "investigation-notConducted"
																						: "investigation-muted"
																				}
																			>
																				Not conducted
																			</Button>
																		</Col>
																	</Row>
																</Card>
															</React.Fragment>
														);
													})}
												</Col>
											</Row>
										</React.Fragment>
									) : (
										<Empty description={<span>No tests prescribed.</span>}>
											<Button
												type="primary"
												onClick={this.onAddNewInvestigation}
											>
												Add Now
											</Button>
										</Empty>
									)}
								</Col>
							</Row>
						</Layout.Content>
					</Layout>
				</Layout>
			</React.Fragment>
		);
	}
}
