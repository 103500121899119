import React from "react";
import _ from "lodash";
import { Form, Button, InputNumber, Row, Col, Empty, Icon } from "antd";
import VitalImage from "../assets/vitals.png";
import { isEmpty, castArray } from "lodash";
import { openNotification } from "../util";
import axios from "axios";

const formItemLayout = {
	colon: false
};
const formTailLayout = {
	// labelCol: { span: 4 },
	// wrapperCol: { span: 6, offset: 0 }
};

class DynamicRule extends React.Component {
	state = {
		// vitals: vitals.questions,
		height: null,
		weight: null
	};
	check = () => {
		let vitalsBody = {
			appointment_id: this.props.location.state.appointmentId,
			category_id: 1,
			patient_answers: []
		};
		let fda = this.props.form.getFieldsValue();

		let arrayed = Object.keys(fda).forEach(function(key) {
			console.log(key);
			if (key.includes("/")) {
				vitalsBody.patient_answers.push({
					person_ans_id: key.split("/")[1].split("-")[0],
					answers: [
						{
							ans_id: key.split("/")[1].split("-")[0],
							answer: fda[key] !== null ? `${fda[key]}` : "",
							is_active: 1
						}
					]
				});
			} else {
				vitalsBody.patient_answers.push({
					qid: key.split("-")[1],
					answers: [
						{
							answer: fda[key] !== null ? `${fda[key]}` : ""
						}
					]
				});
			}
		});
		console.log(vitalsBody);

		axios
			.post(`${process.env.REACT_APP_API_ROOT}/ans_update`, vitalsBody)
			.then(res => {
				if (res.data.status) {
					console.log(res.data);
					openNotification("success", "Vitals saved");
				} else {
					openNotification("error", "Something went wrong");
				}
			});
	};
	onChangeHandler = (key, value) => {
		this.setState({
			[key]: value
		});
		setTimeout(() => {
			this.setValue();
		}, 1000);
	};
	setValue = key => {
		if (this.state.height !== null && this.state.weight !== null) {
			this.setBMIValue();
			this.setBSAValue();
		}
	};
	setBSAValue = () => {
		let surfaceArea = (this.state.height * this.state.weight) / 3600;
		let bsa = Math.sqrt(surfaceArea);
		let bsaNew = bsa;
		let resultingBSA = bsaNew === 0 ? null : Number(bsaNew).toFixed(2);
		// find relavant field and assign value
		let ccc = this.props.vitals.find(x => x.web_name === "b_s_a");

		let findFeildName = isEmpty(ccc.answers)
			? `${ccc.web_name}-${ccc.id}`
			: `${ccc.web_name}/${ccc.answers[0].ans_id}-${ccc.id}`;

		this.props.form.setFieldsValue({
			[`${findFeildName}`]: _.toNumber(resultingBSA)
		});
	};
	setBMIValue = () => {
		let heightInMts = this.state.height / 100;
		let heightSquare = heightInMts * heightInMts;
		let bmi = this.state.weight / heightSquare;
		let resultingBMI = Number(bmi).toFixed(2);

		// find relavant field and assign value
		let ccc = this.props.vitals.find(x => x.web_name === "bmi");

		let findFeildName = isEmpty(ccc.answers)
			? `${ccc.web_name}-${ccc.id}`
			: `${ccc.web_name}/${ccc.answers[0].ans_id}-${ccc.id}`;

		this.props.form.setFieldsValue({
			[`${findFeildName}`]: _.toNumber(resultingBMI)
		});
	};
	render() {
		const { getFieldDecorator } = this.props.form;
		const { vitals } = this.props;
		return (
			<React.Fragment>
				<Row
					gutter={16}
					style={{
						textAlign: "left",
						marginLeft: "1rem",
						marginTop: "1rem"
					}}
				>
					<Form hideRequiredMark id="dynamicVitals">
						{vitals.map((ques, index) => {
							if (
								ques.web_name !== "pefr" &&
								ques.web_name !== "bp" &&
								ques.web_name !== "height" &&
								ques.web_name !== "weight"
							) {
								return (
									<Col
										span={12}
										style={{
											alignItems: "center",
											display: "flex"
										}}
									>
										<Col span={4}>
											<img src={VitalImage} width="100%" />
										</Col>
										<Col span={6}>
											<Form.Item
												{...formItemLayout}
												label={`${ques.name} (${ques.restrict_value_start} - ${ques.restrict_value_end}) [${ques.unit}]`}
											>
												{getFieldDecorator(
													isEmpty(ques.answers)
														? `${ques.web_name}-${ques.id}`
														: `${ques.web_name}/${ques.answers[0].ans_id}-${ques.id}`,
													{
														rules: [
															{
																type: "number",
																required: false,
																whitespace: true,
																min: ques.restrict_value_start,
																max: ques.restrict_value_end,
																message: "Please enter correct values"
															}
														],
														initialValue: isEmpty(ques.answers)
															? null
															: isEmpty(ques.answers[0].answer)
															? null
															: `${ques.answers[0].answer}`
													}
												)(
													<InputNumber
														disabled={
															ques.web_name === "b_s_a" ||
															ques.web_name === "bmi"
														}
														min={ques.restrict_value_start}
														max={ques.restrict_value_end}
													/>
												)}
											</Form.Item>
										</Col>
									</Col>
								);
							} else if (ques.web_name === "height") {
								return (
									<Col
										span={12}
										style={{
											alignItems: "center",
											display: "flex"
										}}
									>
										<Col span={4}>
											<img src={VitalImage} width="100%" />
										</Col>
										<Col span={6}>
											<Form.Item
												{...formItemLayout}
												label={`${ques.name} (${ques.restrict_value_start} - ${ques.restrict_value_end}) [${ques.unit}]`}
											>
												{getFieldDecorator(
													isEmpty(ques.answers)
														? `${ques.web_name}-${ques.id}`
														: `${ques.web_name}/${ques.answers[0].ans_id}-${ques.id}`,
													{
														rules: [
															{
																type: "number",
																required: false,
																whitespace: true,
																min: ques.restrict_value_start,
																max: ques.restrict_value_end,
																message: "Please enter correct values"
															}
														],
														initialValue: isEmpty(ques.answers)
															? null
															: isEmpty(ques.answers[0].answer)
															? null
															: `${ques.answers[0].answer}`
													}
												)(
													<InputNumber
														onChange={this.onChangeHandler.bind(this, "height")}
														min={ques.restrict_value_start}
														max={ques.restrict_value_end}
													/>
												)}
											</Form.Item>
										</Col>
									</Col>
								);
							} else if (ques.web_name === "weight") {
								return (
									<Col
										span={12}
										style={{
											alignItems: "center",
											display: "flex"
										}}
									>
										<Col span={4}>
											<img src={VitalImage} width="100%" />
										</Col>
										<Col span={6}>
											<Form.Item
												{...formItemLayout}
												label={`${ques.name} (${ques.restrict_value_start} - ${ques.restrict_value_end}) [${ques.unit}]`}
											>
												{getFieldDecorator(
													isEmpty(ques.answers)
														? `${ques.web_name}-${ques.id}`
														: `${ques.web_name}/${ques.answers[0].ans_id}-${ques.id}`,
													{
														rules: [
															{
																type: "number",
																required: false,
																whitespace: true,
																min: ques.restrict_value_start,
																max: ques.restrict_value_end,
																message: "Please enter correct values"
															}
														],
														initialValue: isEmpty(ques.answers)
															? null
															: isEmpty(ques.answers[0].answer)
															? null
															: `${ques.answers[0].answer}`
													}
												)(
													<InputNumber
														onChange={this.onChangeHandler.bind(this, "weight")}
														min={ques.restrict_value_start}
														max={ques.restrict_value_end}
													/>
												)}
											</Form.Item>
										</Col>
									</Col>
								);
							} else if (ques.web_name === "pefr") {
								return (
									<Col
										span={12}
										style={{
											alignItems: "center",
											display: "flex"
										}}
									>
										<Col span={4}>
											<img src={VitalImage} width="100%" />
										</Col>
										<Col span={20}>
											<Form.Item
												{...formItemLayout}
												label={`${ques.name} (${ques.restrict_value_start} - ${ques.restrict_value_end}) [${ques.unit}]`}
											>
												<Col span={6}>
													{getFieldDecorator(
														isEmpty(ques.answers)
															? `${ques.web_name}_1-${ques.id}`
															: `${ques.web_name}_1/${ques.answers[0].ans_id}-${ques.id}`,
														{
															rules: [
																{
																	type: "number",
																	required: false,
																	whitespace: true,
																	min: ques.restrict_value_start,
																	max: ques.restrict_value_end,
																	message: "Please enter correct values"
																}
															],
															initialValue: isEmpty(ques.answers)
																? null
																: isEmpty(ques.answers[0].answer)
																? null
																: `${ques.answers[0].answer}`
														}
													)(
														<InputNumber
															min={ques.restrict_value_start}
															max={ques.restrict_value_end}
														/>
													)}
												</Col>
												<Col span={1}>
													<span>/</span>
												</Col>

												<Col span={6}>
													{getFieldDecorator(
														isEmpty(ques.answers)
															? `${ques.web_name}_2-${ques.id}`
															: `${ques.web_name}_2/${ques.answers[0].ans_id}-${ques.id}`,
														{
															rules: [
																{
																	type: "number",
																	required: false,
																	whitespace: true,
																	min: ques.restrict_value_start,
																	max: ques.restrict_value_end,
																	message: "Please enter correct values"
																}
															],
															initialValue: isEmpty(ques.answers)
																? null
																: isEmpty(ques.answers[0].answer)
																? null
																: `${ques.answers[0].answer}`
														}
													)(
														<InputNumber
															min={ques.restrict_value_start}
															max={ques.restrict_value_end}
														/>
													)}
												</Col>

												<Col span={1}>
													<span>/</span>
												</Col>

												<Col span={6}>
													{getFieldDecorator(
														isEmpty(ques.answers)
															? `${ques.web_name}_3-${ques.id}`
															: `${ques.web_name}_3/${ques.answers[0].ans_id}-${ques.id}`,
														{
															rules: [
																{
																	type: "number",
																	required: false,
																	whitespace: true,
																	min: ques.restrict_value_start,
																	max: ques.restrict_value_end,
																	message: "Please enter correct values"
																}
															],
															initialValue: isEmpty(ques.answers)
																? null
																: isEmpty(ques.answers[0].answer)
																? null
																: `${ques.answers[0].answer}`
														}
													)(
														<InputNumber
															min={ques.restrict_value_start}
															max={ques.restrict_value_end}
														/>
													)}
												</Col>
											</Form.Item>
										</Col>
									</Col>
								);
							} else if (ques.web_name === "bp") {
								return (
									<Col
										span={12}
										style={{
											alignItems: "center",
											display: "flex"
										}}
									>
										<Col span={4}>
											<img src={VitalImage} width="100%" />
										</Col>
										<Col span={20}>
											<Form.Item
												{...formItemLayout}
												label={`${ques.name} (${
													ques.min_value.split("/")[0]
												} - ${ques.max_value.split("/")[0]} / ${
													ques.min_value.split("/")[1]
												} - ${ques.max_value.split("/")[1]}) [${ques.unit}]`}
											>
												<Col span={8}>
													{getFieldDecorator(
														isEmpty(ques.answers)
															? `${ques.web_name}_1-${ques.id}`
															: `${ques.web_name}_1/${ques.answers[0].ans_id}-${ques.id}`,
														{
															rules: [
																{
																	type: "number",
																	required: false,
																	whitespace: true,
																	min: ques.min_value.split("/")[0],
																	max: ques.max_value.split("/")[0],
																	message: "Please enter correct values"
																}
															],
															initialValue: isEmpty(ques.answers)
																? null
																: isEmpty(ques.answers[0].answer)
																? null
																: `${ques.answers[0].answer}`
														}
													)(
														<InputNumber
															min={ques.restrict_value_start}
															max={ques.restrict_value_end}
														/>
													)}
												</Col>
												<Col span={1}>
													<span>/</span>
												</Col>
												<Col span={8}>
													{getFieldDecorator(
														isEmpty(ques.answers)
															? `${ques.web_name}_2-${ques.id}`
															: `${ques.web_name}_2/${ques.answers[0].ans_id}-${ques.id}`,
														{
															rules: [
																{
																	type: "number",
																	required: false,
																	whitespace: true,
																	min: ques.min_value.split("/")[1],
																	max: ques.max_value.split("/")[1],
																	message: "Please enter correct values"
																}
															],
															initialValue: isEmpty(ques.answers)
																? null
																: isEmpty(ques.answers[0].answer)
																? null
																: `${ques.answers[0].answer}`
														}
													)(
														<InputNumber
															min={ques.restrict_value_start}
															max={ques.restrict_value_end}
														/>
													)}
												</Col>
											</Form.Item>
										</Col>
									</Col>
								);
							}
						})}
					</Form>
				</Row>
				<Row
					gutter={16}
					style={{ textAlign: "left", marginLeft: "1rem", marginTop: "1rem" }}
				>
					<Form.Item {...formTailLayout}>
						<Button type="primary" onClick={this.check}>
							Submit
						</Button>
					</Form.Item>
				</Row>
			</React.Fragment>
		);
	}
}

export const WrappedDynamicRule = Form.create({ name: "dynamic_rule" })(
	DynamicRule
);
