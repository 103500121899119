import React, { Component } from "react";
import {
	Drawer,
	Button,
	Input,
	Icon,
	List,
	Avatar,
	Divider,
	Col,
	Row,
	Card,
	DatePicker,
	TimePicker,
	Typography,
	message
} from "antd";
import {
	openNotification,
	getCurrentDoctorId,
	getCurrentClinicId
} from "../util";
import axios from "axios";
import moment from "moment";

export default class DrawerPanel extends React.Component {
	state = {
		parentDrawer: false,
		childDrawer: false,
		searchValue: "",
		searchResults: [],
		patientDetail: {},
		defaultDate: moment(new Date(), "DD-MMM-YYYY"),
		defaultTime: moment(new Date(), "HH:mm:ss"),
		date: moment().format("DD-MMM-YYYY"),
		time: moment().format("HH:mm:ss"),
		apt_legacy_id: null
	};

	showParentDrawer = () => {
		this.setState({
			parentDrawer: true
		});
	};

	onParentClose = () => {
		this.props.refetchQueue();
		setTimeout(() => {
			this.setState({
				parentDrawer: false
			});
		}, 500);
	};

	showChildDrawer = item => {
		this.setState({
			childDrawer: true,
			patientDetail: item
		});
	};

	onChildClose = () => {
		this.setState(
			{
				childDrawer: false,
				apt_legacy_id: null
			},
			() => this.fetchSearchResults()
		);
	};

	onSearch = event => {
		this.setState(
			{
				searchValue: event.target.value
			},
			() => this.fetchSearchResults()
		);
	};

	onDateChange = (date, dateString) => {
		this.setState({
			date: dateString
		});
	};
	onTimeChange = (date, dateString) => {
		this.setState({
			time: dateString
		});
	};
	onInputChange = event => {
		let field = event.target.name;
		let value = event.target.value;
		this.setState({ [field]: value });
	};

	fetchSearchResults(value) {
		axios
			.get(
				`${process.env.REACT_APP_API_ROOT}/v3/person_search?search=${
					this.state.searchValue
				}&queue_page=${0}&page_no=${1}&is_lite=${true}`
			)
			.then(res => {
				if (res.data.details) {
					this.setState({
						searchResults: res.data.details
					});
				} else {
					openNotification("error", `${res.data.message}`);
				}
			});
	}

	postAppointment = () => {
		console.log("time", this.state.time);
		console.log("date", this.state.date);
		const config = {
			headers: { "content-type": "multipart/form-data" }
		};
		let fD = new FormData();
		fD.append("person_id", this.state.patientDetail.person_id);
		fD.append("doctor_id", getCurrentDoctorId());
		fD.append("time", `${this.state.date} ${this.state.time}`);
		// NOTE: adding apt_legacy_id before Hindustan hospital push 
		fD.append("apt_legacy_id", this.state.apt_legacy_id);

		// let timeDifference = moment(this.state.time, "HH:mm A").fromNow(true);
		// console.log(timeDifference);
		// is_waiting is 1 if there are no changes to the time
		// NOTE: commenting below code and moving all to waiting by default as suggested by Ravi
		fD.append("is_waiting", 1);
		// if (timeDifference === "a few seconds") {
		// 	fD.append("is_waiting", 1);
		// } else if (timeDifference === "20 minutes") {
		// 	fD.append("is_waiting", 1);
		// } else if (timeDifference === "a minute") {
		// 	fD.append("is_waiting", 1);
		// } else {
		// 	// is_waiting is 0 if changes made to the time
		// 	fD.append("is_waiting", 1);
		// }
		axios
			.post(`${process.env.REACT_APP_API_ROOT}/appointments`, fD, config)
			.then(res => {
				if (res.data.status) {
					this.onChildClose();
				}
				else {
					message.error("Something went wrong. Please try again")
				}
			});
	};
	getState = item => {
		let filter = item.doctors.filter(
			doc => doc.doctor_id === Number(this.state.currentDoctorId)
		);
		// if (filter.length > 0) {
		return filter[0].queue_state;
		// }
	};
	disabledDate = current => {
		return current && current < moment().subtract(1, "days");
	};

	timePickerChange = (time, timeString) => {
		console.log(time, timeString);
		this.setState({
			appointmentTime: timeString
		});
	};
	componentDidMount() {
		// console.log(moment(new Date(), "DD-MMM-YYYY"));
		// console.log(moment(new Date(), "HH:mm A"));
		this.setState({
			currentDoctorId: getCurrentDoctorId()
		});
	}

	render() {
		return (
			<React.Fragment>
				<Button
					type="link"
					icon="user"
					ghost
					style={{
						marginRight: "1 rem"
					}}
					onClick={this.showParentDrawer}
				>
					Patients
				</Button>
				<Drawer
					width="30%"
					placement="right"
					closable={true}
					onClose={this.onParentClose}
					visible={this.state.parentDrawer}
				>
					<Input
						onChange={this.onSearch}
						placeholder="Search"
						suffix={<Icon type="search" />}
						style={{
							marginTop: "2rem"
						}}
					/>
					<List
						dataSource={this.state.searchResults}
						renderItem={item => (
							<List.Item
								key={item.person_id}
								actions={[
									<div>
										{this.getState(item) === "Add to Queue" ? (
											<Button
												type="primary"
												onClick={this.showChildDrawer.bind(this, item)}
											>
												Appointment
											</Button>
										) : (
											<Button disabled>Added</Button>
										)}
									</div>
								]}
							>
								<List.Item.Meta
									avatar={<Avatar src={item.image} />}
									title={item.name}
									description={item.number}
								/>
							</List.Item>
						)}
					/>
					<Drawer
						width="30%"
						closable={false}
						onClose={this.onChildClose}
						visible={this.state.childDrawer}
					>
						<Row type="flex" justify="center" align="middle">
							<Row type="flex" justify="center" style={{ textAlign: "center" }}>
								<Col>
									<Avatar src={this.state.patientDetail.image} /> <br />
									<Typography.Text>
										{this.state.patientDetail.name}
									</Typography.Text>
									<br />
									<Typography.Text>
										{this.state.patientDetail.number}
									</Typography.Text>
								</Col>
							</Row>
							<Row type="flex" justify="center">
								<Col>
									<Card style={{ marginTop: "2rem" }}>
										<Button
											shape="circle"
											icon="calendar"
											style={{
												position: "relative",
												top: -35,
												right: -128
											}}
										/>

										<div
											style={{
												textAlign: "center"
											}}
										>
											<Typography.Text
												type="secondary"
												style={{
													marginTop: "1rem"
												}}
											>
												Fix an appointment on
											</Typography.Text>
											<br></br>
											<br></br>
											<DatePicker
												defaultValue={this.state.defaultDate}
												format="DD-MMM-YYYY"
												onChange={this.onDateChange}
												style={{
													marginTop: "1rem"
												}}
												disabledDate={this.disabledDate}
											/>
											<TimePicker
												onChange={this.onTimeChange}
												defaultValue={this.state.defaultTime}
												minuteStep={5}
												format="HH:mm A"
												style={{
													marginTop: "1rem"
												}}
											/>
											<br></br>
											<br></br>
											<br></br>
											<Typography.Text
												type="secondary"
												style={{
													marginTop: "2rem"
												}}
											>
												Visit Reference ID (optional)
											</Typography.Text>
											<Input
												onChange={this.onInputChange}
												value={this.state.apt_legacy_id}
												name="apt_legacy_id"
												placeholder="Enter Legacy ID"
												style={{
													marginTop: "1rem"
												}}
											/>

											<Button
												style={{
													marginTop: "1rem"
												}}
												type="primary"
												onClick={this.postAppointment}
											>
												Add to appointments
											</Button>
										</div>
									</Card>
								</Col>
							</Row>
						</Row>
					</Drawer>
				</Drawer>
			</React.Fragment>
		);
	}
}
