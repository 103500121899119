import React, { Component } from "react";
import axios from "axios";
import { Layout, Row, Col, Avatar, PageHeader, Icon } from "antd";
import Navigation from "../navigation";
import SideBar from "../navigation/sidebar";
import RecordUpload from "./upload";
import RecordsView from "./recordsView";

export default class PastRecords extends Component {
	refreshRecords = () => {
		this.refs.records.fetchReports()
	};

	componentDidMount() {
		document.title = "Past records - /dr";
	}
	render() {
		const { location } = this.props;
		return (
			<React.Fragment>
				<Layout>
					<PageHeader
						onBack={() => this.props.history.push("/dashboard")}
						title={
							<div>
								{location.state.avatar ? (
									<Avatar size={35} src={location.state.avatar} />
								) : (
									<Avatar size={35} icon="user" />
								)}
							</div>
						}
						subTitle={
							<div>
								{location.state.patientName}
								{location.state.patientAge}
								{location.state.gender !== null &&
								location.state.gender.length > 0
									? `/ ${location.state.gender}`
									: null}
							</div>
						}
						style={{
							textAlign: "left"
						}}
						backIcon={<Icon type="close" />}
					/>

					<Layout>
						<Layout.Sider
							style={{
								height: "100vh"
							}}
						>
							<SideBar {...this.props} />
						</Layout.Sider>
						<Layout.Content>
							<Row
								gutter={16}
								type="flex"
								justify="space-between"
								style={{
									height: "100vh"
								}}
							>
								<Col span={16}>
									<RecordsView {...this.props} ref="records" />
								</Col>
								<Col
									span={8}
									style={{
										backgroundColor: "white"
									}}
								>
									<div
										style={{
											height: "50%"
										}}
									>
										<RecordUpload
											{...this.props}
											refresh={this.refreshRecords}
										/>
									</div>
								</Col>
							</Row>
						</Layout.Content>
					</Layout>
				</Layout>
			</React.Fragment>
		);
	}
}
