import React, { Component, Fragment } from "react";
import { Menu, Select, Button, Row, Col, message, Popconfirm } from "antd";
import DrawerPanel from "../patients";
import logo from '../assets/logo.png';
import {getCurrentDoctorSelection} from '../util'
const Option = Select.Option;
export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: JSON.parse(localStorage.getItem("doctors")) || [
        {
          doctor_name: ""
        }
      ]
    };
  }
  handleChange = value => {
    let lsItem = JSON.parse(localStorage.getItem("doctors"));
    let indexedItem = lsItem[value];

    localStorage.setItem("doctorIndex", value);
    localStorage.setItem("clinicId", indexedItem.clinic_id);
    localStorage.setItem("doctorId", indexedItem.doctor_id);
		localStorage.setItem("doctorName", indexedItem.doctor_name);
		message.success(`Switched to ${indexedItem.doctor_name}`);
    setTimeout(() => {
      this.props.refetchQueue();
    }, 500);
  };
  logout = () => {
    localStorage.clear();
    this.props.history.push("/");
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ doctors: JSON.parse(localStorage.getItem("doctors")) });
    }, 100);
  }
  render() {
    const { doctors } = this.state;
    return (
			<React.Fragment>
				<Row gutter={16} type="flex" justify="space-between">
					<Col span={1}>
						<div
							className="logo"
							style={{
								display: "flex",
								color: "white",
								marginTop: "0.8rem"
							}}
						>
							<img src={logo} width="100%" />
						</div>
					</Col>
					<Col span={4}>
						<Select
							defaultValue={[getCurrentDoctorSelection()]}
							key="1"
							onChange={this.handleChange.bind(this)}
							style={{ width: 200 }}
						>
							{doctors.map((doc, index) => {
								return (
									<Option key={index} value={index}>
										{doc.doctor_name}
									</Option>
								);
							})}
						</Select>
					</Col>
					<Col
						span={12}
						style={{
							textAlign: "right"
						}}
					>
						<Button
							type="link"
							icon="plus"
							style={{
								margin: "0px 8px"
							}}
							onClick={() => this.props.history.push("/registration")}
							ghost
						>
							Register
						</Button>
						<DrawerPanel {...this.props} />
						<Popconfirm
							placement="bottomRight"
							title="Are you sure you want to logout?"
							onConfirm={this.logout}
							okText="Yes"
							cancelText="No"
						>
							<Button
								icon="logout"
								type="link"
								style={{
									margin: "0px 8px"
								}}
								ghost
							/>
						</Popconfirm>
					</Col>
				</Row>
			</React.Fragment>
		);
  }
}
