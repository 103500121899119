import React, { Component } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Lightbox1 from './lightbox1'
export default class Lightbox extends Component {
	state = { modalIsOpen: false };
	toggleModal = () => {
		this.setState(state => ({
			modalIsOpen: !state.modalIsOpen
		}));
	};
	render() {
		const { modalIsOpen } = this.state;
		const imageLinks = () => {
			let arr = []
			this.props.images.map((item , index) => {
				arr.push({
					src: item.url,
					width: 1,
					height: 1
				});
			}
			)
			return arr;
		}
		return (
			<React.Fragment>
				<Lightbox1 images={imageLinks()} />
			</React.Fragment>
		);
	}
}
