import React, { Component } from "react";
import { WrappedDynamicRule } from "./form";
import axios from "axios";
import { Layout, Row, Col, Avatar, PageHeader, Icon } from "antd";
import Navigation from "../navigation";
import SideBar from "../navigation/sidebar";

export default class DynamicVitals extends Component {
  state = {
    vitals: []
  };
  fetchVitals = (categoryId, appointmentId, clinicId) => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_ROOT
        }/v4/getQuestions?category_id=${categoryId}&appointment_id=${appointmentId}&clinic_id=${clinicId}`
      )
      .then(res => this.setState({ vitals: res.data.question_list.questions }));
  };
  componentDidMount() {
    document.title = "Vitals - /dr";
    this.fetchVitals(
      1,
      this.props.location.state.appointmentId,
      localStorage.getItem("clinicId")
    );
  }
  render() {
    const { location } = this.props;
    return (
			<React.Fragment>
				<Layout>
					<PageHeader
						onBack={() => this.props.history.push("/dashboard")}
						title={
							<div>
								{location.state.avatar ? (
									<Avatar size={35} src={location.state.avatar} />
								) : (
									<Avatar size={35} icon="user" />
								)}
							</div>
						}
						subTitle={
							<div>
								{location.state.patientName}
								{location.state.patientAge}
								{location.state.gender !== null &&
								location.state.gender.length > 0
									? `/ ${location.state.gender}`
									: null}
							</div>
						}
						style={{
							textAlign: "left"
						}}
						backIcon={<Icon type="close" />}
					/>

					<Layout>
						<Layout.Sider
							style={{
								height: "100vh"
							}}
						>
							<SideBar {...this.props.history} />
						</Layout.Sider>
						<Layout.Content>
							<WrappedDynamicRule vitals={this.state.vitals} {...this.props} />
						</Layout.Content>
					</Layout>
				</Layout>
			</React.Fragment>
		);
  }
}
