import React, { Component, Fragment } from "react";
import { Select, Row, Col } from "antd";
import { NavLink } from "react-router-dom";
const Option = Select.Option;
export default class SideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			doctors: JSON.parse(localStorage.getItem("doctors")) || [
				{
					doctor_name: ""
				}
			],
			responsibilties: []
		};
	}
	handleChange = value => {
		let lsItem = JSON.parse(localStorage.getItem("doctors"));
		let indexedItem = lsItem[value];

		localStorage.setItem("clinicId", indexedItem.clinic_id);
		localStorage.setItem("doctorId", indexedItem.doctor_id);
		localStorage.setItem("doctorName", indexedItem.doctor_name);
		// setTimeout(() => {
		//   this.props.refetchQueue();
		// }, 500);
	};
	checkResponsibilities = () => {
		let respFromLocal = JSON.parse(localStorage.getItem("responsibilities"));
		let allowedResp = [3, 13, 26, 27];
		let intersection = respFromLocal.filter(element =>
			allowedResp.includes(element.id)
		);
		console.log("intersection", intersection);
		let finalResp = [];
		this.setState({
			responsibilties: intersection
		});
	};

	componentDidMount() {
		this.checkResponsibilities();
		setTimeout(() => {
			this.setState({ doctors: JSON.parse(localStorage.getItem("doctors")) });
		}, 100);
	}
	render() {
		const { doctors } = this.state;
		const { state: broadCast } = this.props.location;
		const {
			patientId,
			appointmentId,
			patientName,
			patientAge,
			avatar,
			gender
		} = broadCast;
		return (
			<React.Fragment>
				<Row className="sideBar--list">
					<Col>
						<NavLink
							to={{
								pathname: `/patientSummary`,
								state: {
									patientId,
									appointmentId,
									patientName,
									patientAge,
									avatar,
									gender
								}
							}}
						>
							Patient Profile
						</NavLink>
					</Col>
					{this.state.responsibilties.map((resp, index) => {
						return (
							<React.Fragment>
								{resp.id === 3 && (
									<Col>
										<NavLink
											to={{
												pathname: `/vitals`,
												state: {
													patientId,
													appointmentId,
													patientName,
													patientAge,
													avatar,
													gender
												}
											}}
										>
											Vitals
										</NavLink>
									</Col>
								)}
								{resp.id === 13 && (
									<Col>
										<NavLink
											to={{
												pathname: `/prescriptions`,
												state: {
													patientId,
													appointmentId,
													patientName,
													patientAge,
													avatar,
													gender
												}
											}}
										>
											Prescriptions
										</NavLink>
									</Col>
								)}
								{resp.id === 26 && (
									<Col>
										<NavLink
											to={{
												pathname: `/past`,
												state: {
													patientId,
													appointmentId,
													patientName,
													patientAge,
													avatar,
													gender
												}
											}}
										>
											Past Records
										</NavLink>
									</Col>
								)}
								{resp.id === 27 && (
									<Col>
										<NavLink
											to={{
												pathname: `/investigation`,
												state: {
													patientId,
													appointmentId,
													patientName,
													patientAge,
													avatar,
													gender
												}
											}}
										>
											Today's Investigation
										</NavLink>
									</Col>
								)}
							</React.Fragment>
						);
					})}

					{/* <Col>
            <NavLink
              to={{
                pathname: `/vitals`,
                state: {
                  patientId,
                  appointmentId,
                  patientName,
                  patientAge,
                  avatar,
                  gender
                }
              }}
            >
              Investigations
            </NavLink>
          </Col> */}
				</Row>
			</React.Fragment>
		);
	}
}
