import React, { Component } from "react";
import axios from "axios";
import { Layout, Row, Col, Avatar, PageHeader, Icon } from "antd";
import Navigation from "../navigation";
import SideBar from "../navigation/sidebar";
import empty_prescription from './../assets/empty_prescription.png'
export default class Prescription extends Component {
  state = {
    prescription: null
  };
  fetchPrescription = appointmentId => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_ROOT
        }/v2/today_pdf?appointment_id=${appointmentId}`
      )
      .then(res => this.setState({ prescription: res.data.pdf }));
  };

  componentDidMount() {
    document.title = "Prescription - /dr";
    this.fetchPrescription(this.props.location.state.appointmentId);
  }
  render() {
    const { location } = this.props;

    return (
			<React.Fragment>
				<Layout>
					<PageHeader
						onBack={() => this.props.history.push("/dashboard")}
						title={
							<div>
								{location.state.avatar ? (
									<Avatar size={35} src={location.state.avatar} />
								) : (
									<Avatar size={35} icon="user" />
								)}
							</div>
						}
						subTitle={
							<div>
								{location.state.patientName}
								{location.state.patientAge}
								{location.state.gender !== null &&
								location.state.gender.length > 0
									? `/ ${location.state.gender}`
									: null}
							</div>
						}
						style={{
							textAlign: "left"
						}}
						backIcon={<Icon type="close" />}
					/>

					<Layout>
						<Layout.Sider
							style={{
								height: "100vh"
							}}
						>
							<SideBar {...this.props} />
						</Layout.Sider>
						<Layout.Content>
							{this.state.prescription === undefined ? (
								<img
									src={empty_prescription}
									style={{ width: "25%", margin: "5rem auto" }}
								/>
							) : (
								<embed
									src={this.state.prescription}
									type="application/pdf"
									height="100%"
									width="100%"
								/>
							)}
						</Layout.Content>
					</Layout>
				</Layout>
			</React.Fragment>
		);
  }
}
