import React from "react";
import { requestOptions, API_ROOT } from "../util";
import { Form, Icon, Input, Button, Checkbox, Row, Col } from "antd";
import { openNotification, date } from "../util";
import axios from "axios";
import { ClinicContext } from "../main";
class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			doctors: [],
			doctorId: null,
			clinicId: null,
			doctorName: ""
		};
		this.update = values => this.setState(values);
	}
	handleSubmit = event => {
		this.setState({
			loading: true
		});
		event.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const getFieldValues = this.props.form.getFieldsValue([
					"userName",
					"password"
				]);

				let fetchData = {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Basic ${btoa(
							getFieldValues.userName + ":" + getFieldValues.password
						)}`
					}
				};

				fetch(`${API_ROOT}/login`, fetchData)
					.then(res => res.json())
					.then(data => {
						if (data.status == true) {
							openNotification("success", "Logged In");
							localStorage.setItem("date", date(new Date()));
							localStorage.setItem("token", data.token);
							localStorage.setItem("doctors", JSON.stringify(data.doctors));

							// NOTE: for demo instance
							// localStorage.setItem('clinicId', data.clinic_id);
							// localStorage.setItem('doctorId', data.doctor_id);
							// localStorage.setItem('doctorName', data.user_name);

							// NOTE: for all other instances
							localStorage.setItem("clinicId", data.doctors[0].clinic_id);
							localStorage.setItem("doctorId", data.doctors[0].doctor_id);
							localStorage.setItem("doctorName", data.doctors[0].doctor_name);

							this.setState({
								loading: false
							});
							this.getResponsibilities(data.token);
						} else {
							this.setState({
								loading: false
							});
							openNotification("error", `${data.message}`);
						}
					});
			} else {
				this.setState({
					loading: false
				});
			}
		});
	};
	getResponsibilities = token => {
		axios
			.get(`${process.env.REACT_APP_API_ROOT}/responsibility`)
			.then(res => {
				if (res.status === 200) {
					localStorage.setItem(
						"responsibilities",
						JSON.stringify(res.data.responsibilities)
					);
					this.props.props.history.push(`/dashboard`);
				} else {
					openNotification("error", "Something went wrong");
				}
			})
			.catch(err => openNotification("error", "Something went wrong"));
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<div>
				<ClinicContext.Consumer>
					{context => (
						<Row
							gutter={16}
							type="flex"
							justify="space-around"
							align="middle"
							style={{
								marginTop: "10rem"
							}}
						>
							<Col span={8}>
								<Form.Item>
									{getFieldDecorator("userName", {
										rules: [
											{ required: true, message: "Please input your username!" }
										]
									})(
										<Input
											name="email"
											prefix={
												<Icon
													type="user"
													style={{ color: "rgba(0,0,0,.25)" }}
												/>
											}
											placeholder="Username"
										/>
									)}
								</Form.Item>
								<Form.Item>
									{getFieldDecorator("password", {
										rules: [
											{ required: true, message: "Please input your Password!" }
										]
									})(
										<Input
											name="password"
											prefix={
												<Icon
													type="lock"
													style={{ color: "rgba(0,0,0,.25)" }}
												/>
											}
											type="password"
											placeholder="Password"
										/>
									)}
								</Form.Item>
								<Form.Item>
									<Button
										loading={this.state.loading}
										type="primary"
										onClick={this.handleSubmit}
									>
										Login
									</Button>
								</Form.Item>
							</Col>
						</Row>
					)}
				</ClinicContext.Consumer>
			</div>
		);
	}
}
Login.contextType = ClinicContext;
export const LoginForm = Form.create({ name: "Login" })(Login);
