import { notification } from "antd";
import moment from "moment";

export const API_ROOT = `${process.env.REACT_APP_API_ROOT}`;

export const openNotification = (type, message) => {
  if (type === "success") {
    notification["success"]({
      message: `Success`,
      description: `${message}`
    });
  } else {
    notification["error"]({
      message: "Something went wrong",
      description: `${message}`
    });
  }
};

// fake data generator
export const fakeData = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

export const getls = (item) => {
  return JSON.parse(localStorage.getItem(item))
}

export const time = (time) => {
  return  moment(time).format("hh:mm A")
}
export const timeAppointment = (time) => {
  return  moment(time).format("hh:mm:ss")
}
export const date = (time) => {
  return  moment(time).format("YYYY-MM-DD")
}

export const calculateAge = (birthDate, otherDate) => {
    birthDate = new Date(birthDate);
    otherDate = new Date(otherDate);

    let years = (otherDate.getFullYear() - birthDate.getFullYear());

    if (otherDate.getMonth() < birthDate.getMonth() ||
        otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
        years--;
    }

    return years;
}

export const getCurrentDoctorId = () => {
  return localStorage.getItem("doctorId")
}
export const getCurrentDoctorSelection = () => {
	let doctorIndex = localStorage.getItem("doctorIndex");
  return doctorIndex === null ? 0 : parseInt(doctorIndex);
}
export const getCurrentClinicId = () => {
  return localStorage.getItem("clinicId")
}
