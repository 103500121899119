import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Tabs, Badge, Layout, Menu, Button, message, Input } from "antd";
import QueueHOC from "./queueHOC";
import queue from "../new_queue.json";
import { openNotification, getls, time, date } from "../util";
import { ClinicContext } from "../main";
import axios from "axios";
import Navigation from "../navigation";
import DrawerPanel from "../patients";
import EmptyCompleted from "../assets/empty_completed.png";
import EmptyQueue from "../assets/empty_queue.png";
import EmptyWaiting from "../assets/empty_waiting.png";
import _ from "lodash";
const TabPane = Tabs.TabPane;
const { Header, Footer, Sider, Content } = Layout;

export default class Queue extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			currentTab: "waiting-1",
			waiting: [],
			queue: [
				{ count: 0, info: [] },
				{ count: 0, info: [] }
			],
			completed: [],
			today: date(new Date()),
			date: null,
			completed_count: 0,
			waiting_count: 0,
			searchTerm: ""
		};
	}
	tabCallback = key => {
		this.setState({
			currentTab: key
		});
		let keyMod = key.split("-");

		if (keyMod[0] == "waiting" || keyMod[0] == "completed") {
			this.fetchQueue(keyMod[1], null);
		} else if (keyMod[0] == "queue") {
			this.fetchQueue("2", keyMod[2]);
		} else {
			this.fetchQueue("1", null);
		}
	};
	fetchQueue(queueType = "1", sessionId = null) {
		this.setState({ loading: true });
		let date = localStorage.getItem("date");
		let clinicId = localStorage.getItem("clinicId");

		axios
			.get(
				`${process.env.REACT_APP_API_ROOT}/v3/appointments?date=${date}&clinic_id=${clinicId}&queueType=${queueType}&sessionId=${sessionId}`
			)
			.then(res => {
				if (res.data.status == true) {
					this.setState({
						loading: false,
						waiting: res.data.waiting.map((item, index) => {
							return {
								...item,
								apt_legacy_id:
									item.apt_legacy_id == null ? "" : item.apt_legacy_id
							};
						}),
						queue: res.data.queue,
						completed: res.data.completed,
						completed_count: res.data.completed_count,
						waiting_count: res.data.waiting_count
					});
				} else {
					openNotification("error", `${res.data.message}`);
					this.setState({
						loading: false
					});
				}
			});
	}
	postUpdates = key => {
		console.log('postUpdates')
		this.setState({loading: true})
		let stateItems = { ...this.state };
		let body = {
			changed_appointments: []
		};
		if (key == "waiting") {
			stateItems.waiting.map((item, index) => {
				body.changed_appointments.push({
					q_id: item.id,
					state: 1,
					order: index
				});
			});
		}
		if (key == "queue-0") {
			stateItems.queue[0].info.map((item, index) => {
				body.changed_appointments.push({
					q_id: item.id,
					state: 3,
					order: index
				});
			});
		}
		if (key == "queue-1") {
			stateItems.queue[1].info.map((item, index) => {
				body.changed_appointments.push({
					q_id: item.id,
					state: 3,
					order: index
				});
			});
		}
		if (key == "completed") {
			stateItems.completed.map((item, index) => {
				body.changed_appointments.push({
					q_id: item.id,
					state: 2,
					order: index
				});
			});
		}
		console.log("body", body);
		axios
			.post(`${process.env.REACT_APP_API_ROOT}/v3/appointments_switch`, body)
			.then(res => {
				this.tabCallback(this.state.currentTab);
				console.log(res.data);
				this.setState({loading: false})
				message.success("Queue Updated", 2.5);
			});
	};
	updateItems = (key, values) => {
		let stateItems = { ...this.state };
		this.setState({loading: true})
		switch (key) {
			case "waiting":
				stateItems.waiting = values;
				this.setState(stateItems, () => this.postUpdates("waiting"));
				break;
			case "queue-0":
				stateItems.queue[0].info = values;
				this.setState(stateItems, () => this.postUpdates("queue-0"));
				break;
			case "queue-1":
				stateItems.queue[1].info = values;
				this.setState(stateItems, () => this.postUpdates("queue-1"));
				break;
			case "completed":
				stateItems.completed = values;
				this.setState(stateItems, () => this.postUpdates("completed"));
				break;
			default:
		}
	};
	appointmentSwitch = (id, state, order) => {
		let body = {
			changed_appointments: {
				q_id: parseInt(id),
				state: state,
				order: order === null ? null : parseInt(order)
			}
			// NOTE: changing order to default to null
			// suggested by ravi
		};
		axios
			.post(`${process.env.REACT_APP_API_ROOT}/v2/appointments_switch`, body)
			.then(res => {
				this.tabCallback(this.state.currentTab);
				console.log(res.data);
				message.success("Queue Updated", 2.5);
			});
	};
	appointmentDelete = id => {
		let body = {
			data: { id: [id] }
		};
		axios
			.delete(`${process.env.REACT_APP_API_ROOT}/v2/appointments`, body)
			.then(res => {
				this.tabCallback(this.state.currentTab);
				console.log("appointmentDelete", res.data);
				message.success("Queue Updated", 2.5);
			});
	};

	moveWaiting = (id, state, context) => {
		console.log("context", context);
		// if (context === "cancel") {
		// 	this.appointmentDelete(id);
		// } else {
		// 	this.appointmentSwitch(id, 1, context === "top" ? "0" : null);
		// }

		// waiting 1
		// appointments 3
		// completed 2
		// let lastItem = this.state.waiting[this.state.waiting.length - 1].position

		switch (context) {
			case "cancel":
				this.appointmentDelete(id);
				break;
			case "completed":
				this.appointmentSwitch(id, 2, 0);
				break;
			// case "bottom":
			// 	this.appointmentSwitch(id, 1, lastItem);
			// 	break;
			case "waiting":
				this.appointmentSwitch(id, 1, null);
				break;
			default:
				this.appointmentSwitch(id, 1, context === "top" ? "0" : null);
				break;
		}
	};
	searchPatient = event => {
		let searchTerm = event.target.value.toLowerCase();
		this.setState({ searchTerm });
	};

	componentDidMount() {
		document.title = "Queue - /dr";
		setTimeout(() => {
			this.fetchQueue("1", null);
		}, 100);
	}
	render() {
		const filteredPatients = this.state.waiting.filter(patient => {
			return (
				patient.name
					.toLowerCase()
					.indexOf(this.state.searchTerm.toLowerCase()) !== -1 ||
				patient.apt_legacy_id.includes(this.state.searchTerm)
			);
			// if (this.state.searchTerm.length > 0) {
			// 	return patient.apt_legacy_id.includes(this.state.searchTerm);
			// }
			// else {
			// 	return (
			// 		patient.name
			// 			.toLowerCase()
			// 			.indexOf(this.state.searchTerm.toLowerCase()) !== -1 ||
			// 		patient.apt_legacy_id.includes(this.state.searchTerm)
			// 	);
			// }
			// if (patient.apt_legacy_id !== null) {
			// return (patient.apt_legacy_id.indexOf(this.state.searchTerm) !== -1);
			// }
		});
		return (
			<React.Fragment>
				<Layout
					style={{
						minHeight: "100vh"
					}}
				>
					<Header>
						<Navigation
							refetchQueue={this.fetchQueue.bind(this)}
							{...this.props}
						/>
					</Header>
					<Content>
						<Tabs
							defaultActiveKey="waiting-1"
							onChange={this.tabCallback}
							className="queueHOC--tabs"
							tabBarExtraContent={
								<div style={{ marginRight: "1rem" }}>
									{this.state.currentTab.includes("waiting") && (
										<Input.Search onChange={this.searchPatient} allowClear />
									)}
								</div>
							}
						>
							<TabPane
								tab={
									<div>
										Waiting <Badge count={this.state.waiting_count} />
									</div>
								}
								key="waiting"
							>
								{filteredPatients.length > 0 ? (
									<QueueHOC
										eventType={this.moveWaiting}
										loading={this.state.loading}
										data={_.sortBy(filteredPatients, ["position"])}
										update={this.updateItems}
										stateKey="waiting"
									/>
								) : (
									<img
										style={{
											width: "25%",
											margin: "5rem auto"
										}}
										src={EmptyWaiting}
									/>
								)}
							</TabPane>
							{this.state.queue.map((queue, index) => {
								return (
									<TabPane
										tab={
											<div>
												{time(queue.from)} - {time(queue.to)}{" "}
												<Badge count={`${queue.count}`} />
											</div>
										}
										key={`queue-${index + 1}-${queue.id}`}
									>
										{this.state.queue[index].info.length > 0 ? (
											<QueueHOC
												eventType={this.moveWaiting}
												loading={this.state.loading}
												data={_.sortBy(this.state.queue[index].info, [
													"position"
												])}
												update={this.updateItems}
												stateKey={`queue-${index}`}
											/>
										) : (
											<img
												style={{
													width: "25%",
													margin: "5rem auto"
												}}
												src={EmptyQueue}
											/>
										)}
									</TabPane>
								);
							})}
							<TabPane
								tab={
									<div>
										Completed <Badge count={this.state.completed_count} />
									</div>
								}
								key="completed-0"
							>
								{this.state.completed.length > 0 ? (
									<QueueHOC
										eventType={this.moveWaiting}
										loading={this.state.loading}
										data={_.sortBy(this.state.completed, ["position"])}
										update={this.updateItems}
										stateKey="completed"
									/>
								) : (
									<img
										style={{
											width: "25%",
											margin: "5rem auto"
										}}
										src={EmptyCompleted}
									/>
								)}
							</TabPane>
						</Tabs>
					</Content>
				</Layout>
			</React.Fragment>
		);
	}
}
Queue.contextType = ClinicContext;
