import React, { Fragment, Component, Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import DynamicVitals from "./dynamicVitals";
import { openNotification } from "./util";
import Registration from './registration/index';
import Queue from "./queue/index.jsx";
import Auth from "./auth";
import axios from "axios";
import Prescription from './prescription';
import PatientSummary from './patients/patientSummary'
import PastRecords from './pastRecords/index';
import TodayInvestigation from './todayInvestigaiton/index'
// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    config.headers.token = localStorage.token;
    return config;
  },
  function(error) {
    // openNotification("error", `Something went wrong`);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    if (response.status === 401) {
      //TODO: API returns "token Missing" for other things too. So can't use response interceptor for route gaurding
      localStorage.clear();
      window.location.href = "/";
    }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);
const ProtectedRoutes = props => {
  if (!localStorage.token) {
    // window.location.href = "/";
    return props.children;
  } else {
    return props.children;
  }
};

export const ClinicContext = React.createContext();
export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: [],
      doctorId: null,
      clinicId: null,
      doctorName: ""
    };
    this.update = values => this.setState(values);
  }
  render() {
    return (
			<main>
				<BrowserRouter>
					<Switch>
						{/* <Suspense fallback={<div>Loading...</div>}> */}
						<ClinicContext.Provider
							value={{ state: this.state, update: this.update }}
						>
							<Route exact path="/" component={Auth} />
							<ProtectedRoutes>
								<Route exact path="/dashboard" component={Queue} />
								<Route exact path="/registration" component={Registration} />
								<Route exact path="/vitals" component={DynamicVitals} />
								<Route exact path="/prescriptions" component={Prescription} />
								<Route
									exact
									path="/patientSummary"
									component={PatientSummary}
								/>
								<Route exact path="/past" component={PastRecords} />
								<Route exact path="/investigation" component={TodayInvestigation} />
							</ProtectedRoutes>
						</ClinicContext.Provider>
						{/* </Suspense> */}
					</Switch>
				</BrowserRouter>
			</main>
		);
  }
}
