import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
	Avatar,
	Row,
	Col,
	Badge,
	Icon,
	Skeleton,
	Popover,
	Button,
	Modal
} from "antd";
import { calculateAge } from "../util";
import getAge from "get-age";
import moment from "moment";
const { confirm } = Modal;
export default class QueueItem extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		function showConfirm(props) {
			confirm({
				title: "Do you want to cancel this appointment?",
				content: "This action cannot be undone",
				onOk() {
					console.log(props);
					props.eventType(`${props.patient.id}`, "waiting", "cancel");
				},
				onCancel() {
					console.log("Cancel");
				}
			});
		}
		const { patient, eventType } = this.props;
		const age = age => {
			let formatDate = moment(age).format("DD-MM-YYYY");
			// let iso = formatDate.toISOString();
			let actualAge = getAge(formatDate);
			return age;
		};
		const HamburgerIconSVG = () => (
			<svg
				width="18"
				height="17"
				viewBox="0 0 18 17"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					opacity="0.54"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0ZM0 17H18V15H0V17Z"
					fill="black"
				/>
			</svg>
		);
		const HamburgerIcon = props => (
			<Icon component={HamburgerIconSVG} {...props} />
		);

		if (this.props.loading) {
			return <Skeleton avatar active paragraph={{ rows: 1 }} />;
		} else {
			return (
				<div
					style={{
						height: "50px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center"
					}}
				>
					<Row type="flex" justify="space-around" align="middle">
						<Col span={2}>
							{patient.image_new ? (
								<Avatar size={35} src={patient.image_new} />
							) : (
								<Avatar size={35} icon="user" />
							)}
						</Col>
						<Col
							span={4}
							style={{
								textAlign: "left"
							}}
						>
							<Link
								to={{
									pathname: `/patientSummary`,
									state: {
										patientId: patient.person_id,
										appointmentId: patient.id,
										patientName: patient.name,
										patientAge: age(patient.age),
										avatar: patient.image_new,
										gender: patient.gender
									}
								}}
								style={{
									color: "currentColor"
								}}
							>
								{patient.name}
							</Link>
							<br />
							{/* {age(patient.age)} */}
							{`${moment().year() - patient.age.split("-")[2]}yrs`}
							{/* {moment(patient.age).year() - moment().year()} */}
							{patient.gender !== null && patient.gender.length > 0
								? ` / ${patient.gender} `
								: null}
							{patient.apt_legacy_id.length > 0 ? (
								<>
									/ <mark>{patient.apt_legacy_id}</mark>
								</>
							) : null}
						</Col>
						<Col span={10}>
							<Link
								to={{
									pathname: `/vitals`,
									state: {
										patientId: patient.person_id,
										appointmentId: patient.id,
										patientName: patient.name,
										patientAge: age(patient.age),
										avatar: patient.image_new,
										gender: patient.gender
									}
								}}
							>
								<Badge
									color={patient.vitals === "0" ? "#E0E0E0" : "#F2994A"}
									text="Vitals"
									className="badges--queueHOC"
								/>
							</Link>
							<Badge
								color={patient.history === "0" ? "#E0E0E0" : "#F2C94C"}
								text="HOPI"
								className="badges--queueHOC"
							/>
							<Badge
								color={patient.diagnosis === "0" ? "#E0E0E0" : "#6FCF97"}
								text="Diagnosis"
								className="badges--queueHOC"
							/>
							<Link
								to={{
									pathname: `/prescriptions`,
									state: {
										patientId: patient.person_id,
										appointmentId: patient.id,
										patientName: patient.name,
										patientAge: age(patient.age),
										avatar: patient.image_new,
										gender: patient.gender
									}
								}}
							>
								<Badge
									color={patient.prescription === "0" ? "#E0E0E0" : "#368FF7"}
									text="Prescription"
									className="badges--queueHOC"
								/>
							</Link>
						</Col>
						<Col
							span={4}
							style={{
								textAlign: "right"
							}}
						>
							{patient.previous_appointment == null
								? "New Patient"
								: `Last Visit: ${moment().format("ddd, Do MMM YYYY")}`}
						</Col>
						<Col span={1} offset={2}>
							<HamburgerIcon />
						</Col>
						<Col span={1}>
							<Popover
								placement="left"
								content={
									<div>
										<React.Fragment>
											{this.props.stateKey === "waiting" && (
												<React.Fragment>
													<Col>
														<Button
															block
															onClick={() =>
																this.props.eventType(
																	`${patient.id}`,
																	"waiting",
																	"top"
																)
															}
														>
															Move to Top
														</Button>
														{/* 
                            <Button
                              block
                              onClick={() =>
                                this.props.eventType(
                                  `${patient.id}`,
                                  "waiting",
                                  "bottom"
                                )
                              }
                            >
                              Move to Bottom
                            </Button> */}
														<Button
															block
															onClick={() =>
																this.props.eventType(
																	`${patient.id}`,
																	"waiting",
																	"completed"
																)
															}
														>
															Move to Completed
														</Button>

														<Button
															block
															onClick={() => showConfirm(this.props)}
														>
															Cancel appointment
														</Button>
													</Col>
												</React.Fragment>
											)}
											{(this.props.stateKey === "queue-0" ||
												this.props.stateKey === "queue-1") && (
												<React.Fragment>
													<Button
														block
														onClick={() =>
															this.props.eventType(
																`${patient.id}`,
																"queue",
																"waiting"
															)
														}
													>
														Move to Waiting
													</Button>
													<Button block onClick={() => showConfirm(this.props)}>
														Cancel appointment
													</Button>
												</React.Fragment>
											)}

											{this.props.stateKey === "completed" && (
												<React.Fragment>
													<Button
														block
														onClick={() =>
															this.props.eventType(
																`${patient.id}`,
																"completed",
																"waiting"
															)
														}
													>
														Move to Waiting
													</Button>
												</React.Fragment>
											)}
										</React.Fragment>
									</div>
								}
								trigger="click"
							>
								<Button
									icon="more"
									type="link"
									className="button--queueItem-more"
								/>
							</Popover>
						</Col>
					</Row>
				</div>
			);
		}
	}
}
