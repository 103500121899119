import React from "react";
import {
	Form,
	Select,
	Radio,
	Button,
	Row,
	Col,
	Input,
	DatePicker,
	TimePicker,
	Tag,
	Checkbox,
	Drawer,
	Avatar,
	PageHeader,
	Icon,
	Popconfirm,
	InputNumber,
	message
} from "antd";
import moment from "moment";
import _ from "lodash";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { openNotification, getls, date, timeAppointment } from "../util";
import axios from "axios";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const { TextArea } = Input;

const { Option } = Select;
const { CheckableTag } = Tag;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const dateFormat = "DD-MM-YYYY";

class Demo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showComponent: false,
			referbySalutation: "Dr",
			salutation: "Mr",
			referedby: "",
			initial: "",
			firstname: "",
			lastname: "",
			gender: "",
			place: "",
			mobileno: "",
			moreInfo: "",
			emergencyName: "",
			emergencyNumber: "",
			descNotes: "",
			apt_legacy_id: "",
			legacy_id: "",
			allergies: [],
			selectedAllergies: [],
			noneStated: false,
			salutations: [
				{
					value: "Mr.",
					label: "Mr."
				},
				{
					value: "Dr.",
					label: "Dr."
				},
				{
					value: "Mrs.",
					label: "Mrs."
				},
				{
					value: "Miss.",
					label: "Miss."
				},
				{
					value: "Ms.",
					label: "Ms."
				},
				{
					value: "Master.",
					label: "Master."
				},
				{
					value: "Baby.",
					label: "Baby."
				},
				{
					value: "Mx.",
					label: "Mx."
				}
			],
			profilePhotoId: null,
			profilePhoto: null,
			dobType: "date",
			doctors: [],
			currentDoctor: [],
			addressSuggestions: [],
			mapData: {},
			dob: null,
			appointmentDate: moment(new Date(), dateFormat),
			appointmentTime: moment(new Date(), "HH:mm A"),
			appointmentForClinic: null,
			appointmentForDoc: null,
			customDOB: "years"
		};
	}
	checkValues = () => {
		let fname = _.isEmpty(this.state.firstname);
		let sex = _.isEmpty(this.state.gender);
		let mobileno = _.isEmpty(this.state.mobileno);
		let appointmentDate = _.isEmpty(this.state.appointmentDate);
		let appointmentTime = _.isEmpty(this.state.appointmentTime);
		if (fname || sex || mobileno || appointmentDate || appointmentTime) {
			let message = []
			let msg = [
				{
					label: "First Name",
					var: fname
				},
				{
					label: "Gender",
					var: sex
				},
				{
					label: "Mobile Number",
					var: mobileno
				},
				{
					label: "Appointment Date",
					var: appointmentDate
				},
				{
					label: "Appointment Time",
					var: appointmentTime
				}
			];
			let filteredMessage = msg.filter(x => x.var == true);
			filteredMessage.map((item, ind) => {
				return message.push(item.label)
			})
			openNotification("error", `Please fill these fields: ${message.join(',')}`);
			return false;
		}
		else {
			return true;
		}
		
	}
	
	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (this.checkValues()) {
				console.log("Received values of form: ", values);
				const newName = `${this.state.initial} ${this.state.firstname}`;
				//2018-12-10 09:16:33
				const appointmentDateTime = `${date(
					this.state.appointmentDate
				)} ${timeAppointment(this.state.appointmentTime)}`;

				let image_id = this.state.profilePhotoId;
				let profilePhoto = this.state.profilePhoto;
				let allergies = [];
				this.state.selectedAllergies.map(item => allergies.push(item.name));
				allergies.push(this.state.moreInfo);
				let reqBody = {
					phone_number: [
						{
							name: "",
							number: this.state.mobileno
						}
					],
					basic_info: {
						initial: this.state.initial,
						salutation: this.state.salutation,
						name: this.state.firstname,
						last_name: this.state.lastname,
						age: moment(this.state.dob).format(dateFormat),
						allergy: allergies.join(","),
						doctor_reference: `${this.state.referbySalutation} ${this.state.referedby}`,
						sex: this.state.gender,
						descriptive_notes: this.state.descNotes,
						legacy_id: this.state.legacy_id
					},
					emergency: [
						{
							name: this.state.emergencyName,
							number: this.state.emergencyNumber
						}
					],
					addresses: [
						{
							city: this.state.secondary_text,
							address_line: this.state.mapData.description,
							country: "India",
							state: ""
						}
					],
					image: {
						id: Number(image_id),
						url: profilePhoto
					},
					appointment: {
						doctor_id: this.state.appointmentForDoc,
						time: appointmentDateTime,
						place: this.state.secondary_text,
						is_waiting: 1,
						is_lite: 0,
						apt_legacy_id: this.state.apt_legacy_id
					}
				};
				console.log(reqBody);
				//
				axios
					.post(`${process.env.REACT_APP_API_ROOT}/v2/registration`, reqBody)
					.then(res => {
						console.log(res.data);
						if (res.data.status) {
							this.props.history.goBack();
						} else {
							message.error(`${res.data.message}. Please try again.`);
						}
					});
			}
		});
	};
	check = () => {
		let vitalsBody = {
			appointment_id: this.props.location.state.appointmentId,
			category_id: 1,
			patient_answers: []
		};
		let fda = this.props.form.getFieldsValue();

		let arrayed = Object.keys(fda).forEach(function(key) {
			console.log(key);
			if (key.includes("/")) {
				vitalsBody.patient_answers.push({
					person_ans_id: key.split("/")[1].split("-")[0],
					answers: [
						{
							ans_id: key.split("/")[1].split("-")[0],
							answer: fda[key] !== null ? `${fda[key]}` : "",
							is_active: 1
						}
					]
				});
			} else {
				vitalsBody.patient_answers.push({
					qid: key.split("-")[1],
					answers: [
						{
							answer: fda[key] !== null ? `${fda[key]}` : ""
						}
					]
				});
			}
		});

		axios
			.post(`${process.env.REACT_APP_API_ROOT}/ans_update`, vitalsBody)
			.then(res => {
				if (res.data.status) {
					console.log(res.data);
					openNotification("success", "Logged In");
				} else {
					openNotification("error", "Something went wrong");
				}
			});
	};
	onTakePhoto(dataUri) {
		// Do stuff with the photo...
		console.log("tookPhoto", dataUri);

		fetch(dataUri)
			.then(res => res.blob())
			.then(blob => {
				console.log(blob);
				var fd = new FormData();
				const file = new File([blob], "filename.jpeg");
				fd.append("image", file);
				// Upload
				fetch(`${process.env.REACT_APP_API_ROOT}/add_image`, {
					method: "POST",
					body: fd,
					headers: { token: localStorage.getItem("token") }
				})
					.then(res => res.json())
					.then(res => {
						console.log(res);
						localStorage.setItem("profilePhotoId", res.image_id);
						localStorage.setItem("profilePhoto", res.url);
						this.setState({
							profilePhotoId: res.image_id,
							profilePhoto: res.url,
							showComponent: !this.state.showComponent
						});
						// if (res.status === true) {
						//   window.location.reload();
						// }
					});
			});
	}

	onCameraError(error) {
		console.error("onCameraError", error);
	}

	onCameraStart(stream) {
		console.log("onCameraStart");
	}

	onCameraStop() {
		console.log("onCameraStop");
	}

	onToggle = () => {
		this.setState({
			showComponent: !this.state.showComponent
		});
	};
	referedbySalutationChange = value => {
		console.log("referbySalutation", value);
		this.setState({ referbySalutation: value });
	};
	salutationChange = value => {
		console.log("salutation", value);
		this.setState({ salutation: value });

		switch (value) {
			case "Mr.":
				this.setState({
					gender: "Male"
				});
				break;
			case "Master.":
				this.setState({
					gender: "Male"
				});
				break;
			case "Miss.":
				this.setState({
					gender: "Female"
				});
				break;
			case "Ms.":
				this.setState({
					gender: "Female"
				});
				break;
			case "Mrs.":
				this.setState({
					gender: "Female"
				});
				break;
			case "Mx.":
				this.setState({
					gender: "Other"
				});
				break;
			default:
		}
	};
	inputChange = e => {
		let name = e.target.name;
		let value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		this.setState({
			[name]: value
		});
	};
	inputNumberChange = value => {
		this.setState({
			customDOBNumber: value
		});
	};
	noneStatedChange = e => {
		this.setState({
			noneStated: !this.state.noneStated,
			selectedAllergies: [],
			moreInfo: ""
		});
	};

	mobilenoChange = e => {
		const re = /^[0-9\b]+$/;
		if (e.target.value === "" || re.test(e.target.value)) {
			this.setState({
				[e.target.name]: e.target.value
			});
		}
		console.log(this.state.mobileno, this.state.emergencyNumber);
	};

	radioBtnChange = e => {
		console.log("gender", e.target.value);
		this.setState({
			gender: e.target.value
		});
	};

	appointmentDateChange = (date, dateString) => {
		this.setState({
			appointmentDate: dateString
		});
	};

	disabledDate = current => {
		return current && current < moment().subtract(1, "days");
	};

	timePickerChange = (time, timeString) => {
		console.log(time, timeString);
		this.setState({
			appointmentTime: time
		});
	};

	handleAllergyChange = (tag, checked) => {
		console.log("before", this.state.noneStated);
		this.setState({ noneStated: false }, () => this.addAllergies(tag, checked));
		console.log("after", this.state.noneStated);
	};
	addAllergies = (tag, checked) => {
		const { selectedAllergies } = this.state;

		const nextSelectedAllergies = checked
			? [...selectedAllergies, tag]
			: selectedAllergies.filter(t => t !== tag);
		this.setState({ selectedAllergies: nextSelectedAllergies });
	};
	fetchAllergies = () => {
		axios.get(`${process.env.REACT_APP_API_ROOT}/allergy_list`).then(res => {
			if (res.data.status) {
				this.setState({
					allergies: res.data.list
				});
				console.log(res.data.list);
			} else {
				openNotification("error", `${res.data.message}`);
			}
		});
	};

	// COMBAK: dobCalc
	dobChange = (date, dateString) => {
		console.log(date, dateString);
		this.setState({
			dob: dateString
		});
	};
	onDOBtypeChange = e => {
		this.setState({
			dobType: `${e.target.value}`
		});
	};
	onCustomDOBChange = e => {
		this.setState({
			customDOB: `${e.target.value}`
		});
	};
	confirmAge = () => {
		if (this.state.dobType === "custom") {
			let dobDate = moment(new Date()).subtract(
				this.state.customDOBNumber,
				this.state.customDOB
			);
			this.setState({ dob: dobDate });
		} else {
			console.log(this.state.dob);
			this.setState({ dob: this.state.dob });
		}
	};
	handleDoctorChange = value => {
		let lsItem = JSON.parse(localStorage.getItem("doctors"));
		let indexedItem = lsItem[value];
		this.setState({
			doctorIndex: value,
			appointmentForClinic: indexedItem.clinic_id,
			appointmentForDoc: indexedItem.doctor_id
		});
	};
	initState = () => {
		let doctorsList = JSON.parse(localStorage.getItem("doctors"));
		let doctorIndex = Number(localStorage.getItem("doctorIndex"));
		let aptClinicGhost = doctorsList[doctorIndex];
		let aptClinic = aptClinicGhost.clinic_id;
		let aptDocGhost = doctorsList[doctorIndex];
		let aptDoc = aptDocGhost.doctor_id;
		this.setState({
			doctors: doctorsList,
			doctorIndex: doctorIndex,
			appointmentForClinic: aptClinic,
			appointmentForDoc: aptDoc
		});
	};

	// YOUR_API_KEY
	componentDidMount() {
		this.fetchAllergies();
		this.initState();
	}
	render() {
		// const { getFieldDecorator } = this.props.form;
		const formItemLayout = {
			labelCol: { span: 6 },
			wrapperCol: { span: 14 }
		};
		return (
			<div>
				<PageHeader
					onBack={() => this.props.history.push("/dashboard")}
					title={<div>Registration</div>}
					style={{
						textAlign: "left"
					}}
					backIcon={<Icon type="close" />}
				/>

				<Form
					{...formItemLayout}
					onSubmit={this.handleSubmit}
					labelAlign="left"
				>
					<Row
						type="flex"
						justify="space-around"
						align="top"
						style={{
							backgroundColor: "aliceblue"
						}}
					>
						<Col
							span={6}
							style={{
								marginTop: "1rem",
								display: "flex",
								justifyContent: "center"
							}}
						>
							<Form.Item>
								<div>
									{this.state.profilePhoto !== null ? (
										<div>
											<Avatar
												style={{
													width: 128,
													height: 128
												}}
												src={this.state.profilePhoto}
											/>
											<Button icon="camera" onClick={this.onToggle}>
												Take Photo
											</Button>
										</div>
									) : (
										<Button
											type="primary"
											shape="circle"
											icon="camera"
											onClick={this.onToggle}
											style={{
												width: 128,
												height: 128
											}}
										/>
									)}
								</div>

								<Drawer
									title="Take Photo"
									placement="bottom"
									width="100%"
									height="100%"
									closable={true}
									onClose={this.onToggle}
									visible={this.state.showComponent}
									destroyOnClose
								>
									<div>
										{this.state.showComponent ? (
											<Camera
												onTakePhoto={dataUri => {
													this.onTakePhoto(dataUri);
												}}
												onCameraError={error => {
													this.onCameraError(error);
												}}
												idealFacingMode={FACING_MODES.ENVIRONMENT}
												idealResolution={{ width: 640, height: 480 }}
												imageType={IMAGE_TYPES.JPG}
												imageCompression={0}
												isMaxResolution={false}
												isImageMirror={true}
												isSilentMode={true}
												isDisplayStartCameraError={true}
												sizeFactor={1}
												onCameraStart={stream => {
													this.onCameraStart(stream);
												}}
												onCameraStop={() => {
													this.onCameraStop();
												}}
											/>
										) : null}
									</div>
								</Drawer>
							</Form.Item>
						</Col>

						<Col span={16}>
							<Row type="flex" gutter={20} className="m-3">
								<Col span={12} className="registration--label">
									Referral doctor
								</Col>
								<Col span={12} className="registration--label">
									Appointment for doctor
								</Col>
							</Row>
							<Row type="flex" gutter={20} className="m-3">
								<Col span={3}>
									<Select
										defaultValue="Dr"
										name="referedbySalutation"
										onChange={this.referedbySalutationChange}
									>
										<Option value="Dr">Dr</Option>
										<Option value="dr">Dr</Option>
									</Select>
								</Col>
								<Col span={9}>
									<Input
										placeholder="Referred by"
										name="referedby"
										onChange={this.inputChange}
									/>
								</Col>
								<Col span={12}>
									<Select
										defaultValue={this.state.doctorIndex}
										value={this.state.doctorIndex}
										key="1"
										onChange={this.handleDoctorChange.bind(this)}
										// style={{ width: 200 }}
									>
										{this.state.doctors.map((doc, index) => {
											return (
												<Option key={index} value={index}>
													{doc.doctor_name}
												</Option>
											);
										})}
									</Select>
								</Col>
							</Row>

							<Row type="flex" gutter={20} className="m-3">
								<Col span={3} className="registration--label">
									Salutation <span style={{ color: "red" }}>*</span>
								</Col>
								<Col span={2} className="registration--label">
									Initial
								</Col>
								<Col span={7} className="registration--label">
									First Name <span style={{ color: "red" }}>*</span>
								</Col>
								<Col span={12} className="registration--label">
									Last Name
								</Col>
							</Row>
							<Row type="flex" gutter={20} className="m-3">
								<Col span={3}>
									<Select
										defaultValue="Mr"
										name="salutation"
										onChange={this.salutationChange}
									>
										{this.state.salutations.map((salut, index) => {
											return (
												<Option key={index} value={salut.value}>
													{salut.label}
												</Option>
											);
										})}
									</Select>
								</Col>
								<Col span={2}>
									<Input
										placeholder="initial"
										name="initial"
										onChange={this.inputChange}
									/>
								</Col>
								<Col span={7}>
									<Input
										placeholder="Enter first name"
										name="firstname"
										onChange={this.inputChange}
									/>
								</Col>
								<Col span={12}>
									<Input
										placeholder="Enter last name"
										name="lastname"
										onChange={this.inputChange}
									/>
								</Col>
							</Row>
							<Row type="flex" gutter={20} className="m-3">
								<Col span={12} className="registration--label">
									Gender <span style={{ color: "red" }}>*</span>
								</Col>
								<Col span={12} className="registration--label">
									Age <span style={{ color: "red" }}>*</span>
								</Col>
							</Row>

							<Row type="flex" gutter={20} className="m-3">
								<Col span={12}>
									<RadioGroup
										onChange={this.radioBtnChange}
										value={this.state.gender}
									>
										<Radio value="Male">Male</Radio>
										<Radio value="Female">Female</Radio>
										<Radio value="Other">Other</Radio>
									</RadioGroup>
								</Col>
								<Col span={6}>
									<Popconfirm
										placement="bottom"
										title={[
											<div>
												<Row type="flex">
													<Col span={24} style={{ textAlign: "center" }}>
														<RadioGroup
															onChange={this.onDOBtypeChange}
															defaultValue="date"
														>
															<RadioButton value="date">
																Date of Birth
															</RadioButton>
															<RadioButton value="custom">Custom</RadioButton>
														</RadioGroup>
													</Col>
												</Row>
												<Row>
													{this.state.dobType === "date" ? (
														<Col span={24} style={{ marginTop: "0.5rem" }}>
															<Row>
																<DatePicker
																	defaultValue={moment(new Date(), dateFormat)}
																	format={dateFormat}
																	onChange={this.dobChange}
																/>
															</Row>
														</Col>
													) : (
														<Col span={24}>
															<Row
																type="flex"
																gutter={16}
																justify="center"
																style={{ marginTop: "0.5rem" }}
															>
																<InputNumber
																	name="customDOBNumber"
																	onChange={this.inputNumberChange}
																/>
															</Row>
															<Row
																type="flex"
																gutter={16}
																justify="center"
																style={{ marginTop: "0.5rem" }}
															>
																<RadioGroup
																	onChange={this.onCustomDOBChange}
																	defaultValue={this.state.customDOB}
																>
																	<RadioButton value="days">Days</RadioButton>
																	<RadioButton value="weeks">Weeks</RadioButton>
																	<RadioButton value="months">
																		Months
																	</RadioButton>
																	<RadioButton value="years">Years</RadioButton>
																</RadioGroup>
															</Row>
														</Col>
													)}
												</Row>
											</div>
										]}
										onConfirm={this.confirmAge}
										// onCancel={cancel}
										okText="Yes"
										cancelText="No"
										icon={<React.Fragment />}
									>
										<Button>Select/Change Age</Button>
									</Popconfirm>
								</Col>
								<Col span={6}>
									<DatePicker
										value={
											this.state.dob === null
												? moment()
												: moment(this.state.dob, dateFormat)
										}
										format={dateFormat}
										disabled={true}
									/>
								</Col>
							</Row>
							<Row type="flex" gutter={20} className="m-3">
								<Col span={12} className="registration--label">
									Address
								</Col>
								<Col span={12} className="registration--label">
									Mobile number <span style={{ color: "red" }}>*</span>
								</Col>
							</Row>

							<Row type="flex" gutter={20} className="m-3">
								<Col span={12}>
									{/* <AutoComplete
                    dataSource={this.state.addressSuggestions}
                    style={{ width: 200 }}
                    onSelect={value => console.log(value)}
                    onSearch={this.handleAddressSearch}
                    placeholder="Enter place"
                    name="place"
                  /> */}
									<GooglePlacesAutocomplete
										onSelect={desc =>
											this.setState(
												{
													mapData: desc,
													mainText: desc.structured_formatting.main_text,
													secondary_text:
														desc.structured_formatting.secondary_text
												},
												console.log(desc)
											)
										}
										renderInput={props => (
											<div className="custom-wrapper">
												<Input
													// Custom properties
													{...props}
												/>
											</div>
										)}
										renderSuggestions={(
											active,
											suggestions,
											onSelectSuggestion
										) => (
											<div
												className="suggestions-container"
												style={{
													border: "1px solid black"
												}}
											>
												{suggestions.map(suggestion => (
													<div
														className="suggestion"
														onClick={event =>
															onSelectSuggestion(suggestion, event)
														}
													>
														{suggestion.description}
													</div>
												))}
											</div>
										)}
									/>
								</Col>
								<Col span={12}>
									<Input
										placeholder="Enter mobile number"
										name="mobileno"
										value={this.state.mobileno}
										onChange={this.mobilenoChange}
									/>
								</Col>
							</Row>
							<Row type="flex" gutter={20} className="m-3">
								<Col span={6} className="registration--label">
									Appointment Date
								</Col>

								<Col span={6} className="registration--label">
									Appointment Time
								</Col>
								<Col span={6} className="registration--label">
									Legacy ID
								</Col>
								<Col span={6} className="registration--label">
									Visit Reference ID
								</Col>
							</Row>
							<Row type="flex" gutter={20} className="m-3">
								<Col span={6}>
									<DatePicker
										defaultValue={this.state.appointmentDate}
										format={dateFormat}
										onChange={this.appointmentDateChange}
										disabledDate={this.disabledDate}
									/>
								</Col>

								<Col span={6}>
									<TimePicker
										use12Hours
										format="HH:mm A"
										defaultValue={this.state.appointmentTime}
										onChange={this.timePickerChange}
									/>
								</Col>
								<Col span={6}>
									<Input
										placeholder="Enter legacy ID"
										name="legacy_id"
										onChange={this.inputChange}
									/>
								</Col>
								<Col span={6}>
									<Input
										placeholder="Enter Visit reference ID"
										name="apt_legacy_id"
										onChange={this.inputChange}
									/>
								</Col>
							</Row>
							<Row
								type="flex"
								gutter={20}
								className="m-3"
								style={{ marginBottom: "0.8rem" }}
							>
								<Col span={12} className="registration--label">
									Allergy to Medication
								</Col>
								<Col span={12} style={{ textAlign: "right" }}>
									<Checkbox
										checked={this.state.noneStated}
										name="noneStated"
										onChange={this.noneStatedChange}
									>
										None stated by patient
									</Checkbox>
								</Col>
							</Row>
							<Row type="flex" gutter={20}>
								{this.state.allergies.map((tag, index) => {
									return (
										<Col
											span={4}
											key={index}
											style={{ textAlign: "left", margin: "0.2rem 0rem" }}
										>
											<CheckableTag
												key={index}
												checked={this.state.selectedAllergies.indexOf(tag) > -1}
												onChange={checked =>
													this.handleAllergyChange(tag, checked)
												}
												style={{
													border:
														this.state.selectedAllergies.indexOf(tag) > -1
															? "1px solid #1890ff"
															: "1px solid #2b2b2b"
												}}
											>
												{tag.name}
											</CheckableTag>
										</Col>
									);
								})}
							</Row>

							<Row type="flex" gutter={20} className="m-3">
								<Col span={24}>
									<TextArea
										rows={4}
										name="moreInfo"
										value={this.state.moreInfo}
										placeholder="More information on Allergy To Medication"
										onChange={this.inputChange}
									/>
								</Col>
							</Row>
							<Row type="flex" gutter={20} className="m-3">
								<Col span={12}>
									<Input
										placeholder="Emergency contact name"
										name="emergencyName"
										onChange={this.inputChange}
									/>
								</Col>
								<Col span={12}>
									<Input
										placeholder="Emergency contact number"
										name="emergencyNumber"
										value={this.state.emergencyNumber}
										onChange={this.mobilenoChange}
									/>
								</Col>
							</Row>
							<Row type="flex" gutter={20} className="m-3">
								<Col span={24}>
									<Input
										placeholder="Descriptive notes - optional"
										name="descNotes"
										onChange={this.inputChange}
									/>
								</Col>
							</Row>

							<Row type="flex" justify="space-around">
								<Col span={24} style={{ textAlign: "left", marginTop: "1rem" }}>
									<Form.Item>
										<Button type="primary" htmlType="submit">
											Submit
										</Button>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</div>
		);
	}
}

const Registration = Form.create({ name: "validate_other" })(Demo);
export default Registration;
