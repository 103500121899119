import React, { Component } from "react";
import axios from "axios";
import { Layout, Row, Col, Avatar, PageHeader, Icon, Typography, Divider, Card } from "antd";
import Navigation from "../navigation";
import SideBar from "../navigation/sidebar";
import empty_prescription from "./../assets/empty_prescription.png";
import { animationFrame } from "rxjs/internal/scheduler/animationFrame";
export default class PatientSummary extends Component {
	state = {
		summary: []
	};
	fetchSummary = patientId => {
		axios
			.get(
				`${process.env.REACT_APP_API_ROOT}/v2/simple_summary?person_id=${patientId}`
			)
			.then(res => {
				if (res.data.status) {
					this.setState({ summary: res.data.summary });
				}
			});
	};

	componentDidMount() {
		document.title = "Patient Summary - /dr";
		this.fetchSummary(this.props.location.state.patientId);
	}
	render() {
		const { location } = this.props;
		const { summary } = this.state;

		return (
			<React.Fragment>
				<Layout>
					<PageHeader
						onBack={() => this.props.history.push("/dashboard")}
						title={
							<div>
								{location.state.avatar ? (
									<Avatar size={35} src={location.state.avatar} />
								) : (
									<Avatar size={35} icon="user" />
								)}
							</div>
						}
						subTitle={
							<div>
								{location.state.patientName}
								{location.state.patientAge}
								{location.state.gender !== null &&
								location.state.gender.length > 0
									? `/ ${location.state.gender}`
									: null}
							</div>
						}
						style={{
							textAlign: "left"
						}}
						backIcon={<Icon type="close" />}
						extra={<strong>Patient ID - {location.state.patientId}</strong>}
					/>

					<Layout>
						<Layout.Sider
							style={{
								height: "100vh"
							}}
						>
							<SideBar {...this.props} />
						</Layout.Sider>
						<Layout.Content
							style={{
								textAlign: "left",
								paddingLeft: "1rem"
							}}
						>
							{summary.length && (
								<Row type="flex" gutter={16}>
									{summary.map((item, index) => {
										return (
											<React.Fragment key={index}>
												<Col span={10} style={{ margin: "1rem 0rem 1rem" }}>
													<Card title={item.title}>
														{Array.isArray(item.value) && (
															<>
																{item.value.map((it, ind) => {
																	return (
																		<>
																			{`${it.title} : ${it.value}`}
																			<br></br>
																		</>
																	);
																})}
															</>
														)}
													</Card>
												</Col>
											</React.Fragment>
										);
									})}
								</Row>
							)}
							{/* {summary.length && (
								<>
									{summary.map((item, index) => {
										return (
											<React.Fragment key={index}>
												<h4>{item.title}</h4>
												<>
													{Array.isArray(item.value) && (
														<>
															{item.value.map((it, ind) => {
																return (
																	<>
																		<h5>{it.title}</h5>
																		<h6>{it.value}</h6>
																		<br />
																	</>
																);
															})}
														</>
													)}
												</>
												<Divider />
												{/* <br /> 
											</React.Fragment>
										);
									})}
								</>
							)} */}
						</Layout.Content>
					</Layout>
				</Layout>
			</React.Fragment>
		);
	}
}
